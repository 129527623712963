const kegel = new URL('./images/screenshot_kegel.png', import.meta.url);
const body = new URL('./images/screenshot_body.png', import.meta.url);
const mind = new URL('./images/screenshot_mind.png', import.meta.url);
const mastery = new URL('./images/screenshot_mastery.png', import.meta.url);
const play = new URL('./images/screenshot_play.png', import.meta.url);

export const reviews = [
  {
    name: "Joann",
    review: "I felt like I was losing my appeal, but I think it’s coming back."
  },{
    name: "Stefan",
    review: "I feel way more confident approaching women."
  },{
    name: "Michael",
    review: "Life-changing."
  },{
    name: "Phill",
    review: "I feel stronger and can move more easily."
  },{
    name: "Jessica",
    review: "I didn’t honestly realize things could be this good!"
  },{
    name: "Alex",
    review: "I feel so energized. It’s like I have a new enthusiasm for life."
  },{
    name: "Helen",
    review: "It took a while to see the benefits, but at a point it started coming together."
  },{
    name: "Steve",
    review: "My body and my brain feel more aligned."
  },{
    name: "Chris",
    review: "Before, it was like I was looking for a fix I couldn’t quite reach. I feel more satisfied, now."
  }, {
    name: "Mary",
    review: "I wish I’d known about this ten years ago!"
  }
]

export const data = [
  {
    id: "kegel",
    link: kegel,
    text: "Kegels strengthen the all-important pelvic floor muscles. Senses will show you how to do Kegels, in structured training sessions."
  }, {
    id: "body",
    link: body,
    text: "Physical movement practices deepen your knowledge of your body, and help you tone up so that you can move with ease and joy."
  }, {
    id: "mind",
    link: mind,
    text: "Meditations improve your connection with yourself and your body, enhancing the quality of your intimate experiences."
  }, {
    id: "mastery",
    link: mastery,
    text: "Educational videos from expert coaches guide you as you navigate relationships, and help you overcome any obstacles to enjoying your full potential."
  }, {
    id: "play",
    link: play,
    text: "New from Senses: explore exciting, interactive ways to connect with yourself and others."
  }
]
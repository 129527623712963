import './index.css'; 
import { reviews, data } from './data.js';

//Section 4 change screenshots and text
const section4 = document.querySelector('.section-4');

function changeButtonState(id) {
  const button = section4.querySelector(`.${id}`);
  document.querySelector('.button_active').classList.remove('button_active');
  button.classList.add('button_active');
}

function changeState({id, text, link}) {
  const section4Text = section4.querySelector('.section-4__text');
  const section4Img = section4.querySelector('.section-4__img');
  const button = section4.querySelector(`.${id}`);

  button.addEventListener('click', () => {
    section4Img.src = link;
    section4Text.textContent = text;
    changeButtonState(id)
  });
}

data.forEach(item => changeState(item));

//Reviews
function renderReviews({name, review}) {
  const template = document.querySelector('.review-card');
  const card = template.cloneNode(true);

  card.querySelector('.review-card__title').textContent = name;
  card.querySelector('.review-card__text').textContent = review;

  document.querySelector('.reviews__wrapper').append(card);
}

reviews.forEach(review => renderReviews(review));


//FAQ accordion
const answers = document.querySelectorAll('.accordion__item');
answers.forEach((event) => {
  event.addEventListener('click', () => {
    if (event.classList.contains('active')) {
      event.classList.remove('active');
    } else {
      event.classList.add('active');
    }
  });
});
